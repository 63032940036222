import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import usePrinters from './printerSelectors/usePrinters';
import { useStateValue } from '../../../providers/StateProvider';
import {
  DEFAULT_PRINTER_LABEL,
  PRINTER_LABELS,
} from '../../../utils/printing/zebra/utils/labelGenerator';
import SelectPrinterIconButton from './printerSelectors/SelectPrinterIconButton';

const MultiPrinterSelectionContainer = () => {
  const { t } = useTranslation();
  const [initialize, setInitialize] = useState(false);
  const [{ currentStationId }, dispatch] = useStateValue();
  const [printerTypeList, setPrinterTypeList] = useState([]);
  const {
    disabledPrinter,
    dispatchSelectedPrinter,
    handleSelectedPrinterChange,
    handleSelectedLabelChange,
    retrievePrinterFromStorage,
    retrieveLabelFromStorage,
    retrievePrinterStorageKey,
    loading,
    data,
    refetch,
  } = usePrinters();

  /**
   * initialize the states of the labels for the given printer type
   * @param {Array<{ value: string; label: string; }> | undefined} labels
   * @param {string} type
   */
  const initLabels = (labels, type) => {
    // Get the currently stored label for the given printer type
    // or null if no label currently stored for the printer type
    const storedLabel = retrieveLabelFromStorage(type);

    // Find the label currently stored based on the list of labels for the printer type
    // Default to a specific label when the currently stored is not in the list of labels anymore
    // or when there is no currently stored label
    const updatedLabelInfo =
      storedLabel && labels
        ? labels.find(({ value }) => value === storedLabel.value) ||
          DEFAULT_PRINTER_LABEL
        : DEFAULT_PRINTER_LABEL;

    if (
      storedLabel?.currentPrinterModel &&
      updatedLabelInfo.printerModels.length > 0
    ) {
      updatedLabelInfo.currentPrinterModel =
        updatedLabelInfo.printerModels.find(
          (printerModel) =>
            printerModel.value === storedLabel.currentPrinterModel
        )?.value || null;
    }

    // Update the context value and localStorage to set the new current label value
    handleSelectedLabelChange(updatedLabelInfo, type);
  };

  const updateSelectedPrinterInfo = (printers, storedPrinter, type) => {
    // Get the updated data for the selected printer from the list of printer coming from the api
    const updatedPrinterInfo = printers.find(
      (printer) => printer.id === storedPrinter.id
    );

    if (updatedPrinterInfo) {
      // Update the context value and localStorage to set the new current printer value
      handleSelectedPrinterChange(updatedPrinterInfo, type);
    } else {
      dispatch({
        type: 'changeAlert',
        newAlert: { type: 'error', message: t('Error.PrinterDoesNotExists') },
      });
      // Remove the localStorage value when the printer is not in the list of printers coming from the api
      localStorage.removeItem(retrievePrinterStorageKey(type));
      dispatchSelectedPrinter(null, type);
    }
  };

  /**
   * initialize the states of the printers for the given printer type
   * @param {Array<{ id: string; }>} printers
   * @param {string} type
   */
  const initPrinters = (printers, type) => {
    // Get the currently stored printer for the given printer type
    const storedPrinter = retrievePrinterFromStorage(type);

    if (storedPrinter) {
      updateSelectedPrinterInfo(printers, storedPrinter, type);
    } else {
      dispatchSelectedPrinter(null, type);
    }
  };

  const handleLabelCurrentPrinterModelChange = (
    currentPrinterModelValue,
    selectedLabel,
    printerType
  ) => {
    if (!selectedLabel) return;

    const updatedLabelInfo = {
      ...selectedLabel,
      currentPrinterModel: currentPrinterModelValue,
    };
    handleSelectedLabelChange(updatedLabelInfo, printerType);
  };

  const handlePrinterChange = (printer, name) => {
    handleSelectedPrinterChange(printer, name);

    printerTypeList.forEach((printerType) => {
      initLabels(PRINTER_LABELS[printerType.name], printerType.name);
      initPrinters(printerType.printers, printerType.name);
    });
  };

  useEffect(() => {
    // re-initialize printerTypeList
    setPrinterTypeList([]);
    refetch();

    if (initialize) {
      setInitialize(false);
    }
  }, [currentStationId]);

  const sortPrinterTypeListPrinters = (dataToSort) => {
    // the data PrinterTypeList contains the key 'printer'
    // this function sorts the value of key 'printer' by name
    return dataToSort.printersList?.map((printersAndType) => ({
      ...printersAndType,
      printers: [...printersAndType.printers].sort((a, b) =>
        a.printerName.localeCompare(b.printerName)
      ),
    }));
  };

  useEffect(() => {
    if (data && !loading) {
      // sorts printers array of printersList with new data
      const sortedData = sortPrinterTypeListPrinters(data);
      setPrinterTypeList(sortedData);
    }
  }, [data, loading]);

  useEffect(() => {
    if (printerTypeList.length && !initialize && !loading) {
      printerTypeList.forEach((printerType) => {
        initLabels(PRINTER_LABELS[printerType.name], printerType.name);
        initPrinters(printerType.printers, printerType.name);
      });
      setInitialize(true);
    }
  }, [printerTypeList, currentStationId, initialize, loading]);

  if (loading || disabledPrinter) return null;

  return printerTypeList.length ? (
    <Box
      data-testid="printer-selector-box-container"
      display="flex"
      gridGap="1em"
    >
      {printerTypeList.map(({ id: printerTypeId, name, printers }) =>
        printers.length ? (
          <SelectPrinterIconButton
            key={printerTypeId}
            printerType={name}
            onPrinterChange={(printer) =>
              handlePrinterChange(printer, name)
            }
            onLabelChange={(label) => handleSelectedLabelChange(label, name)}
            onLabelCurrentPrinterModelChange={(
              currentPrinterModelValue,
              selectedLabel
            ) =>
              handleLabelCurrentPrinterModelChange(
                currentPrinterModelValue,
                selectedLabel,
                name
              )
            }
            printers={printers}
            labels={PRINTER_LABELS[name] || []}
          />
        ) : null
      )}
    </Box>
  ) : null;
};

export default MultiPrinterSelectionContainer;
